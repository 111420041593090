import React, { useEffect, useState } from "react";
import { getP, getC, getN } from "./services";
import { useUtil } from "../../../contexts/UtilContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useData } from "../../../contexts/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../partials/Sidebar";
import toast from "react-hot-toast";
import Notes from "./Notes";
import Note from "./Modals/Note";
import UserSettings from "./Modals/UserSettings";
import ProjectMembers from "./Modals/ProjectMembers";
import ProjectCustomization from "./Modals/ProjectCustomization";
import ProjectNavbar from "../../partials/ProjectNavbar";
import CornerButtons from "../../partials/CornerButtons";

function Index() {
  const { identity, setProjectIdentity } = useAuth();
  const { showLoader } = useUtil();
  const { projectId, categoryId } = useParams();
  const { themes, setProjectTitle, setProjectTheme, setProjectCategories, noteModalOpen, setNoteModalOpen, userSettingsModalOpen, projectMembersModalOpen, projectCustomizationModalOpen } = useData();

  const [notes, setNotes] = useState(null);
  const [project, setProject] = useState(null);
  const [category, setCategory] = useState(null);

  const [selectedNote, setSelectedNote] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      console.warn("GET Project");
      getProject();
    }
  }, [projectId]);

  useEffect(() => {
    if (categoryId) {
      console.warn("GET Category");
      getCategory();

    }
    
  }, [categoryId]);


  const getProject = async () => {
    showLoader(true);

    await getP(projectId)(
      (projectData) => {
        setProject(projectData);
        setProjectTitle(projectData.title);

        // Set Project Identity if indetity exist
        if (identity) {
          const user = projectData.users.find((user) => user.id == identity.id);
          if (user) {
            setProjectIdentity(user.pivot);
          }
        }

        if (themes) {
          let projectThemeObj = {
            index: projectData.theme,
            theme: themes[projectData.theme],
          };

          setProjectTheme(projectThemeObj);
        }

        setProjectCategories(projectData.categories);
      },
      (message) => {
        toast.error(message);
        setProject(null);
      }
    );
    showLoader(false);
  };

  const getCategory = async () => {
    showLoader(true);

    await getC(projectId, categoryId)(
      (categoryData) => {
        setCategory(categoryData);
        setNotes(categoryData.notes);
      },
      (message) => {
        toast.error(message);
        setNotes(null);
        showLoader(false);
        navigate(`/`);
        return;
      }
    );
    showLoader(false);
  };

  // GET requests
  const getNotes = async () => {
    showLoader(true);

    await getN(projectId, categoryId)(
      (data) => {
        setNotes(data);
      },
      (message) => {
        toast.error(message);
        setNotes(null);
      }
    );
    showLoader(false);
  };


  return (
    <div className="theme-custom vh-100">
      {/* {identity && project && category && ( */}
        <>
          <ProjectNavbar project={project} projectId={projectId} category={category} isTrash={false} />

          <div className="position-relative ">
            <Sidebar project={project} projectId={projectId} categoryId={categoryId} />

            <div className="body-content p-2">
              <div className="overflow-auto scrollbar-hidden row g-2 m-0 pb-2 h-100 align-content-start">
                <Notes projectId={projectId} categoryId={categoryId} notes={notes} getNotes={getNotes} setSelectedNote={setSelectedNote} />
              </div>
            </div>
          </div>

          <CornerButtons setSelectedNote={setSelectedNote} />

          {noteModalOpen && <Note selectedNote={selectedNote} getNotes={getNotes} projectId={projectId} categoryId={categoryId} />}

          {userSettingsModalOpen && <UserSettings />}
          {projectMembersModalOpen && <ProjectMembers projectId={projectId} project={project} setProject={setProject} />}
          {projectCustomizationModalOpen && <ProjectCustomization projectId={projectId} setProject={setProject} categoryId={categoryId} setCategory={setCategory} />}
        </>
      {/* )} */}
    </div>
  );
}

export default Index;
