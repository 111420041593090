import { isEmpty } from "lodash";
import React, { useState } from "react";
// import FormWizard from "react-form-wizard-component";
// import "react-form-wizard-component/dist/style.css";

const SignupForm = ({ register, errorMessage }) => {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [diaryName, setDiaryName] = useState("");

  const submit = (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      username: username,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      project_title: !isEmpty(diaryName) ? diaryName : name + "'s Diary",
    };
    
    register(formData);
  };

  // const tabChanged = ({ prevIndex, nextIndex }) => {
  //   // console.log("prevIndex", prevIndex);
  //   // console.log("nextIndex", nextIndex);
  // };

  return (
    <>
      <form className="w-100 p-2" onSubmit={submit}>
        <h4 className="mt-3 mb-2">Personal Informarion</h4>

        <div className="mb-2">
          <label className="form-label">Full Name</label>
          <input
            className="form-control rounded"
            placeholder="Full Name"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            required
          />
        </div>

        <div className="mb-2">
          <label className="form-label">Username</label>
          <input
            className="form-control rounded"
            placeholder="Username"
            type="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            required
          />
          <small className="text-muted">This username is used to login</small>
        </div>

        <div className="mb-2">
          <label className="form-label">Email</label>
          <input
            className="form-control rounded"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            required
          />
        </div>

        <div className="mb-2">
          <label className="form-label">Password</label>
          <input
            className="form-control rounded"
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
        </div>

        <div className="mb-2">
          <label className="form-label">Confirm Password</label>
          <input
            className="form-control rounded"
            placeholder="Confirm password"
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            required
          />
        </div>

        <h4 className="mt-3 mb-2">Diary Information</h4>

        <div className="mb-2">
          <label className="form-label">Diary Name</label>
          <input
            className="form-control rounded"
            placeholder={name + "'s Diary"}
            type="text"
            value={diaryName}
            onChange={(e) => {
              setDiaryName(e.target.value);
            }}
          />
        </div>

        <div className="text-danger mb-2">{errorMessage}</div>

        <div className="">
          <input className="btn btn-primary text-white col-12 rounded" type="submit" value="Create Diary" />
        </div>
      </form>

      {/* 
      <FormWizard stepSize="sm" color={"#ac988e"} onComplete={handleSubmit} onTabChange={tabChanged}>
        <FormWizard.TabContent title="Plan" icon="fa fa-user">
          <h3 className="text-center">Plan</h3>
          <div className="form-outline">
            <label className="form-label">Username</label>
            <input
              className="form-control rounded"
              placeholder="Username"
              type="text"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              required
            />
          </div>
        </FormWizard.TabContent>

        <FormWizard.TabContent title="Diary Details" icon="fa fa-gear">
          <h3 className="text-center">Diary Details</h3>
          <div className="form-outline">
            <label className="form-label">Password</label>
            <input
              className="form-control rounded"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              required
            />
            <div className="text-danger mb-2">{errorMessage}</div>
          </div>
        </FormWizard.TabContent>
      </FormWizard> */}
    </>
  );
};

export default SignupForm;
