import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { DataProvider } from "./contexts/DataContext";
import { UtilProvider } from "./contexts/UtilContext";
import Router from "./Router";

function App() {
    return (
        <>
            <BrowserRouter>
                <DataProvider>
                    <UtilProvider>
                        <AuthProvider>
                            <Router />
                        </AuthProvider>
                    </UtilProvider>
                </DataProvider>
            </BrowserRouter>
        </>
    );
}

export default App;
