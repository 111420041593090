import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useUtil } from "../../contexts/UtilContext";
import { logout } from "../views/Auth/Login/services";
import { useData } from "../../contexts/DataContext";
import FadeInOut from "./FadeInOut";

function ProfilePopup({ project, projectId }) {
  const { identity, projectIdentity, logoutIdentity } = useAuth();
  const { showLoader } = useUtil();
  const { projectTitle, setUserSettingsModalOpen, setProjectMembersModalOpen, setProjectCustomizationModalOpen } = useData();
  const [isOpened, setIsOpened] = useState(false);

  const handleLogout = async () => {
    setIsOpened(false);
    showLoader(true);
    await logout()(
      () => {
        logoutIdentity();
      },
      (msg) => {
        console.log(msg);
      }
    );
    showLoader(false);
  };

  const content = (
    <>
      <div className="bg-primary p-3">
        <small className="text-white mb-0">Display Name</small>
        <h4 className="m-0 text-white text-capitalize">{projectIdentity?.display_name}</h4>
      </div>
      <div className="p-2">
        <ul className="list-unstyled ps-0 mb-0" id="profile-accordion">
          <li className="mb-1">
            <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
              Current Diary
            </button>
            <div className="collapse ps-3 show" id="home-collapse">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                {identity.projects.map((pro) => (
                  <li key={pro.id}>
                    <Link
                      className="link link-body-emphasis d-inline-flex text-decoration-none rounded"
                      to={`/${pro.id}/${pro.categories[0].id}`}
                      onClick={() => {
                        setIsOpened(false);
                      }}
                    >
                      <button className={`nav-link ${projectId == pro.id ? "text-primary" : ""}`}>
                        <span>{projectId == pro.id ? projectTitle : pro.title}</span>
                      </button>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>

          <li className="mb-1">
            <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed text-nowrap" data-bs-toggle="collapse" data-bs-target="#diary-collapse" aria-expanded="false">
              {/* {projectId == project.id ? projectTitle : project.title} Preferences */}
              Diary Preferences
            </button>
            <div className="collapse ps-3" id="diary-collapse">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                <li>
                  <button
                    className="mb-1 bg-transparent border-0 link-body-emphasis d-inline-flex text-decoration-none rounded"
                    onClick={() => {
                      setProjectMembersModalOpen(true);
                      setIsOpened(false);
                    }}
                  >
                    Members
                  </button>
                </li>
                <li>
                  <button className="mb-1 bg-transparent border-0 link-body-emphasis d-inline-flex text-decoration-none rounded">Plan</button>
                </li>
                <li>
                  <button
                    className="bg-transparent border-0 link-body-emphasis d-inline-flex text-decoration-none rounded"
                    onClick={() => {
                      setProjectCustomizationModalOpen(true);
                      setIsOpened(false);
                    }}
                  >
                    Customization
                  </button>
                </li>
              </ul>
            </div>
          </li>

          <li className="">
            <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
              Account
            </button>
            <div className="collapse ps-3" id="account-collapse">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                {/* <li>
                    <Link to={"/asd"} className="link link-body-emphasis d-inline-flex text-decoration-none rounded">
                      Profile
                    </Link>
                  </li> */}
                <li>
                  <button
                    className="mb-1 bg-transparent border-0 link-body-emphasis d-inline-flex text-decoration-none rounded"
                    onClick={() => {
                      setUserSettingsModalOpen(true);
                      setIsOpened(false);
                    }}
                  >
                    Settings
                  </button>
                </li>

                {/* <li>
                    <Link className="link mb-1 link-body-emphasis" to={`/`}>
                      <span>Back To Home</span>
                    </Link>
                  </li> */}

                <li>
                  <Link className="link p-0" to={`/`}>
                    <button className={`mb-1 bg-transparent border-0 link-body-emphasis d-inline-flex text-decoration-none rounded`}>Back to Landing Page</button>
                  </Link>
                </li>

                <li>
                  <button className="bg-transparent border-0 link-body-emphasis d-inline-flex text-decoration-none rounded text-danger" onClick={handleLogout}>
                    Sign out
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <>
      <div className="position-relative">
        <button className="btn btn-primary d-flex align-items-center shadow-sm rounded-circle text-white text-capitalize fs-5" style={{ padding: "8px 16px" }} onClick={() => setIsOpened(!isOpened)}>
          {projectIdentity?.display_name[0]}
        </button>
        {/* {isOpened && */}
        <FadeInOut show={isOpened} duration={100}>
          <div className={`fade-in position-absolute top-100 end-0 mt-1 bg-white rounded overflow-hidden shadow`} style={{ minWidth: "250px" }}>
            {content}
          </div>{" "}
        </FadeInOut>

        {/* } */}
      </div>
    </>

    // <Popup
    //   trigger={
    //     <button className="btn btn-primary d-flex align-items-center shadow-sm rounded-circle text-white text-capitalize fs-5" style={{ padding: "8px 16px" }}>
    //       {projectIdentity?.display_name[0]}
    //     </button>
    //   }
    //   position="bottom right"
    //   // offsetX={15}
    //   offsetY={5}
    //   open={isOpened}
    //   onOpen={() => {
    //     setIsOpened(true);
    //   }}
    //   onClose={() => {
    //     setIsOpened(false);
    //   }}
    // >
    //   {content}
    // </Popup>
  );
}

export default ProfilePopup;
