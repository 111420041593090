import axios from "axios";

export const updateProjectTitle = (projectId, data) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/title`, data);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

export const updateDisplayName = (projectId, data) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/users/${data.projectUserId}`, data);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

export const updateProjectTheme = (projectId, data) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/theme`, data);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

export const addC = (projectId, data) => async (onSuccess, onFail) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories`, data);

  if (response.code == 1) {
    onSuccess(response);
  } else {
    onFail(response.message);
  }
};

export const updateC = (projectId, categoryId, data) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}`, data);

  if (response.code == 1) {
    onSuccess(response);
  } else {
    onFail(response.message);
  }
};

export const deleteC = (projectId, categoryId) => async (onSuccess, onFail) => {
  const response = await axios.delete(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}`);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};
