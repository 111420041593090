import React from "react";
import Icon from "./Icon";
import { useData } from "../../contexts/DataContext";

function CornerButtons({ setSelectedNote }) {
  const { setNoteModalOpen } = useData();

  return (
    <>
      <div className="fixed-custom-right-bottom d-flex flex-column align-items-center">
        {/* Preview button */}
        <button className="btn btn-outline-primary d-flex align-items-center shadow-sm rounded-circle text-white mb-1" style={{ width: "fit-content", padding: "10px" }}>
          <Icon icon={"fa-solid fa-expand"} size="1.2rem" />
        </button>

        <button
          className="btn btn-primary d-flex align-items-center shadow-sm rounded-circle text-white"
          style={{ width: "fit-content", padding: "14px" }}
          onClick={() => {
            setSelectedNote(null);
            setNoteModalOpen(true);
          }}
        >
          <Icon icon={"fa-solid fa-feather"} size="1.2rem" />
        </button>
      </div>
    </>
  );
}

export default CornerButtons;
