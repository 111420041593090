import React, { useEffect, useState } from "react";

const LoginForm = ({ authorize, errorMessage }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const { changeType } = useData();

  // useEffect(() => {

  // }, []);

  const submit = (e) => {
    if (e) e.preventDefault();

    const formData = {
      username: username,
      password: password,
    };

    authorize(formData);
  };

  return (
    <form className="w-100 p-2" onSubmit={submit}>
      <div className="mb-2">
        <label className="form-label">Username</label>
        <input
          className="form-control rounded"
          name="username"
          placeholder="Username"
          type="text"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          required
        />
      </div>

      <div className="mb-2">
        <label className="form-label">Password</label>
        <input
          className="form-control rounded"
          name="password"
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
        />
      </div>
      <div className="text-danger mb-2">{errorMessage}</div>

      <div className="">
        <input className="btn btn-primary text-white col-12 rounded" type="submit" value="Login" />
      </div>
    </form>
  );
};

export default LoginForm;
