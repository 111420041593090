import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../../../../../contexts/AuthContext";
import { addC, deleteC, updateC, updateDisplayName, updateProjectTheme, updateProjectTitle } from "./services";
import { useUtil } from "../../../../../contexts/UtilContext";
import toast from "react-hot-toast";
import Icon from "../../../../partials/Icon";
import { useData } from "../../../../../contexts/DataContext";
import { useNavigate } from "react-router-dom";

function Index({ projectId, categoryId, setCategory }) {
  const { projectIdentity, setProjectIdentity } = useAuth();
  const { showLoader } = useUtil();
  const { themes, icons, projectTitle, setProjectTitle, projectTheme, setProjectTheme, projectCategories, setProjectCategories, projectCustomizationModalOpen, setProjectCustomizationModalOpen } = useData();

  const [newProjectTitle, setNewProjectTitle] = useState(projectTitle);
  const [displayName, setDisplayName] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [icon, setIcon] = useState(0);
  const [sidebarTitle, setSidebarTitle] = useState("");
  const [pageTitle, setPageTitle] = useState("");

  const [newProjectTitleError, setNewProjectTitleError] = useState("");
  const [displayNameError, setDisplayNameError] = useState("");
  const [showSub1, setShowSub1] = useState(false);
  const [showSub2, setShowSub2] = useState(false);

  const [showCategoryDetailsModal, setShowCategoryDetailsModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    console.warn("displayName Updated");
    setDisplayName(projectIdentity?.display_name);
  }, [projectIdentity]);

  const submitProjectTitle = async (e) => {
    e.preventDefault();
    if (newProjectTitle == projectTitle) {
      setNewProjectTitleError("");
      setShowSub2(false);
      // setNewProjectTitleError("The provided Diary Name is the same as the current name");
      return;
    } else {
      setNewProjectTitleError("");
    }

    const formData = {
      title: newProjectTitle,
    };

    showLoader(true);
    await updateProjectTitle(projectId, formData)(
      (message) => {
        setProjectTitle(newProjectTitle);
        setNewProjectTitleError("");
        setShowSub2(false);
        toast.success(message);
      },
      (message) => {
        setNewProjectTitleError(message);
      }
    );
    showLoader(false);
  };

  const submitDisplayName = async (e) => {
    e.preventDefault();
    if (displayName == projectIdentity?.display_name) {
      setDisplayNameError("");
      setShowSub1(false);
      // setDisplayNameError("The provided display name is the same as the current name");
      return;
    } else {
      setDisplayNameError("");
    }

    const formData = {
      projectUserId: projectIdentity.id,
      display_name: displayName,
    };

    showLoader(true);
    await updateDisplayName(projectId, formData)(
      (message) => {
        let oldProjectIdentity = projectIdentity;
        oldProjectIdentity.display_name = displayName;
        setProjectIdentity(oldProjectIdentity);
        setDisplayNameError("");
        setShowSub1(false);
        toast.success(message);
      },
      (message) => {
        setDisplayNameError(message);
      }
    );
    showLoader(false);
  };

  const submitProjectTheme = async (themeIndex) => {
    if (themeIndex == projectTheme.index) {
      return;
    }

    const formData = {
      theme: themeIndex,
    };

    showLoader(true);
    await updateProjectTheme(projectId, formData)(
      (message) => {
        let projectThemeObj = {
          index: themeIndex,
          theme: themes[themeIndex],
        };

        setProjectTheme(projectThemeObj);
        toast.success(message);
      },
      (message) => {
        toast.error(message);
      }
    );
    showLoader(false);
  };

  const clearCategoryData = () => {
    setSelectedCategoryId(null);
    setIcon(0);
    setSidebarTitle("");
    setPageTitle("");
  };

  const submitCategory = async (e) => {
    e.preventDefault();

    const formData = {
      shortTitle: sidebarTitle,
      title: pageTitle,
      icon: icon,
    };

    showLoader(true);
    await addC(projectId, formData)(
      (response) => {
        let oldProjectCategories = projectCategories;
        oldProjectCategories.push(response.data);
        setProjectCategories(oldProjectCategories);

        clearCategoryData();
        setShowCategoryDetailsModal(false);
        toast.success(response.message);
      },
      (message) => {
        toast.error(message);
      }
    );

    showLoader(false);
  };

  const updateCategory = async (e) => {
    e.preventDefault();
    const formData = {
      shortTitle: sidebarTitle,
      title: pageTitle,
      icon: icon,
    };

    showLoader(true);
    await updateC(
      projectId,
      selectedCategoryId,
      formData
    )(
      (response) => {
        let oldProjectCategories = projectCategories;
        let categoryToUpdateIndex = projectCategories.findIndex((category) => category.id == selectedCategoryId);

        if (categoryToUpdateIndex == -1) {
          toast.error("Category not found");
          return;
        }
        // Update category in projectCategories
        oldProjectCategories[categoryToUpdateIndex] = response.data;

        // Update category in category (Main Page Title) if it's same to the current page
        let updatedCategory = oldProjectCategories[categoryToUpdateIndex];
        if (updatedCategory.id == categoryId) {
          setCategory(updatedCategory);
          console.warn("Category Updated");
        }

        setProjectCategories(oldProjectCategories);
        console.warn("ProjectCategories Updated");

        clearCategoryData();
        setShowCategoryDetailsModal(false);
        toast.success(response.message);
      },
      (message) => {
        toast.error(message);
      }
    );

    showLoader(false);
  };

  const deleteCategory = async (catId) => {
    if (!window.confirm("Are you sure you want to delete this section? Keep in mind that this will also erase all the notes it contains!")) return;

    showLoader(true);
    await deleteC(projectId, catId)(
      (message) => {
        let oldProjectCategories = projectCategories.filter((category) => category.id != catId);
        setProjectCategories(oldProjectCategories);

        // If the current page was deleted
        if (catId == categoryId) {
          navigate(`/${projectId}/${oldProjectCategories[0].id}`);
        }

        toast.success(message);
      },
      (message) => {
        toast.error(message);
      }
    );

    showLoader(false);
  };

  return (
    <>
      <Modal
        isOpen={projectCustomizationModalOpen}
        onRequestClose={() => {
          setProjectCustomizationModalOpen(false);
        }}
        className="modalC col-11 col-md-9 col-lg-8 border-sm rounded shadow-sm bg-white overflow-hidden"
        shouldFocusAfterRender={false}
      >
        <div data-aos="fade-up" className="p-3">
          <div className="d-flex justify-content-between">
            <h4 className="m-0 text-capitalize">Diary Customization</h4>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setProjectCustomizationModalOpen(false);
              }}
            ></button>
          </div>
        </div>

        <div className="p-2">
          <div className="custom-overflow modal-height p-2">
            <h5>Diary Name</h5>

            <form
              onSubmit={(e) => {
                submitProjectTitle(e);
              }}
              onChange={() => {
                setShowSub2(true);
              }}
              className="mb-3"
            >
              <div className="mb-1 d-flex justify-content-end">
                <div className="col">
                  <input type="text" className={`form-control ${newProjectTitleError && "border-danger"}`} value={newProjectTitle} onChange={(e) => setNewProjectTitle(e.target.value)} placeholder="Diary Name" required />
                  {newProjectTitleError && (
                    <>
                      <small className="text-danger">{newProjectTitleError}</small>
                    </>
                  )}
                </div>
                {showSub2 && (
                  <button type="submit" className="btn btn-primary text-white ms-1">
                    Save Changes
                  </button>
                )}
              </div>
            </form>

            <h5>Display Name</h5>

            <form
              onSubmit={(e) => {
                submitDisplayName(e);
              }}
              onChange={() => {
                setShowSub1(true);
              }}
              className="mb-3"
            >
              <div className="mb-1 d-flex justify-content-end">
                <div className="col">
                  <input type="text" className={`form-control ${displayNameError && "border-danger"}`} id="l1" value={displayName} onChange={(e) => setDisplayName(e.target.value)} placeholder="Display Name" required />
                  {displayNameError && (
                    <>
                      <small className="text-danger">{displayNameError}</small>
                    </>
                  )}
                </div>
                {showSub1 && (
                  <button type="submit" className="btn btn-primary text-white ms-1">
                    Save Changes
                  </button>
                )}
              </div>
              <small className="text-muted">This name will be displayed to members in this diary</small>
            </form>

            <h5>Diary Theme</h5>
            <form
              // onSubmit={(e) => {
              //   // submitPassword(e);
              // }}
              // onChange={(e) => {
              //   // setShowSub2(true);
              // }}
              className="mb-3"
            >
              {themes.map((theme, index) => (
                <div className="form-check p-0" key={index}>
                  <input
                    className="btn-check"
                    id={`theme${index}`}
                    type="radio"
                    name="theme"
                    value={index}
                    defaultChecked={projectTheme.index == index}
                    onChange={(e) => {
                      submitProjectTheme(e.target.value);
                    }}
                  />

                  <label className="btn p-1 " htmlFor={`theme${index}`}>
                    <div className="d-flex flex-wrap">
                      {/* <div
                        className="theme-color-primary"
                        style={{
                          backgroundColor: theme.primary,
                        }}
                      ></div> */}

                      {/* <div
                        className="theme-color-secondary"
                        style={{
                          backgroundColor: theme.secondary,
                        }}
                      ></div> */}

                      {theme.notes.map((color, colorIndex) => (
                        <div
                          className="theme-color-notes"
                          key={colorIndex}
                          style={{
                            backgroundColor: color,
                          }}
                        ></div>
                      ))}
                    </div>
                  </label>
                </div>
              ))}
            </form>

            <h5>Diary Sections</h5>

            <div className="custom-overflow modal-height">
              <table className="table table-striped table-hover mb-2">
                <thead>
                  <tr>
                    {/* <th scope="col">#</th> */}
                    <th scope="col">Icon</th>
                    <th scope="col">Sidebar Title</th>
                    <th scope="col">Page Title</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {projectCategories.map((category, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          setShowCategoryDetailsModal(true);
                          setSelectedCategoryId(category.id);
                          setIcon(category.icon);
                          setSidebarTitle(category.shortTitle);
                          setPageTitle(category.title);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {/* <td scope="row">{++index}</td> */}
                        <td>
                          <div className="icon-wrap">
                            <Icon iconIndex={category.icon} />
                          </div>
                        </td>
                        <td>{category.shortTitle}</td>
                        <td>{category.title}</td>
                        <td>
                          <div
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent event from bubbling up to <tr>
                              deleteCategory(category.id);
                            }}
                            style={{ width: "fit-content", cursor: "pointer" }}
                          >
                            <Icon icon={"fa-regular fa-trash-can link-danger"} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-outline-primary me-2"
                type="button"
                onClick={() => {
                  // addCategory();
                  setShowCategoryDetailsModal(true);
                  clearCategoryData();
                }}
              >
                New Section +
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showCategoryDetailsModal}
        onRequestClose={() => {
          setShowCategoryDetailsModal(false);
        }}
        className="modalC col-11 col-md-9 col-lg-8 border-sm rounded shadow-sm bg-white overflow-hidden"
        shouldFocusAfterRender={false}
      >
        <div data-aos="fade-up" className="p-4">
          <div className="mb-3 d-flex justify-content-between">
            <h4 className="m-0 text-capitalize">Section Details</h4>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setShowCategoryDetailsModal(false);
              }}
            ></button>
          </div>

          <form
            onSubmit={(e) => {
              if (selectedCategoryId) {
                updateCategory(e);
              } else {
                submitCategory(e);
              }
            }}
            // onChange={() => {

            // }}
          >
            <div className="mb-2">
              <label htmlFor="l2" className="form-label">
                Icon
              </label>
              <div className="d-flex flex-wrap mb-2 p-1">
                {icons.map((iconD, index) => (
                  <div className="form-check me-1 p-0" key={index}>
                    <input
                      className="btn-check"
                      id={`icon${index}`}
                      type="radio"
                      value={index}
                      name="icon"
                      defaultChecked={icon == index}
                      onChange={() => {
                        setIcon(index);
                      }}
                    />
                    <label className="btn d-flex align-items-center justify-content-center p-2 m-1" htmlFor={`icon${index}`}>
                      <Icon icon={iconD} size={19} />
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-2">
              <label htmlFor="l3" className="form-label">
                Sidebar Title
              </label>
              <input type="text" className="form-control" id="l3" value={sidebarTitle} onChange={(e) => setSidebarTitle(e.target.value)} placeholder="Short Title" required />
            </div>

            <div className="mb-2">
              <label htmlFor="l4" className="form-label">
                Page Title
              </label>
              <input type="text" className="form-control" id="l4" value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} placeholder="Full Title" required />
            </div>

            <button type="submit" className="btn btn-primary text-white">
              Save Section
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default Index;
