import axios from "axios";

export const updateFullName = (data) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/update-fullname`, data);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

export const updatePassword = (data) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/update-password`, data);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};
