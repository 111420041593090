import React, { useEffect, useState } from "react";
import SignupForm from "./form";
import Navbar from "../../../partials/Navbar";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../config/authConfig";
import { signup } from "./services";
import { useUtil } from "../../../../contexts/UtilContext";
import { useAuth } from "../../../../contexts/AuthContext";

function Signup() {
  const { showLoader } = useUtil();
  const [errorMessage, setErrorMessage] = useState("");
  const { loginIdentity, logoutIdentity } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (getToken()) {
      navigate("/");
    }
  }, []);

  const register = async (formData) => {
    console.log(formData);

    showLoader(true);
    await signup(formData)(
      (response) => {
        let currentUser = response.data.user;
        if (currentUser.projects.length == 0) {
          logoutIdentity();
        }

        loginIdentity(currentUser, response.data.token, `/${currentUser.projects[0].id}/${currentUser.projects[0].categories[0].id}`);
      },
      (message) => {
        setErrorMessage(message);
      }
    );
    showLoader(false);
  };

  return (
    <>
      <Navbar />
      <div className="container my-4 ">
        <div className="mx-auto col-12 col-md-9 d-flex flex-column justify-content-center">
          <h1 className="my-2 text-center">Start your Diary</h1>

          <SignupForm register={register} errorMessage={errorMessage} />
        </div>
      </div>
    </>
  );
}

export default Signup;
