// Function to set token in local storage
export const setToken = (token) => {
    localStorage.setItem("TOKEN", token);
};

// Function to get token from local storage
export const getToken = () => {
    return localStorage.getItem("TOKEN");
};

// Function to get token from local storage
export const removeToken = () => {
    localStorage.removeItem("TOKEN");
};


// import Cookies from 'js-cookie';

// // Function to set token in cookies
// export const setToken = (token) => {
//     Cookies.set("TOKEN", token, { expires: 7, secure: true, sameSite: 'Strict' }); // expires in 7 days
// };

// // Function to get token from cookies
// export const getToken = () => {
//     return Cookies.get("TOKEN");
// };

// // Function to remove token from cookies
// export const removeToken = () => {
//     Cookies.remove("TOKEN");
// };