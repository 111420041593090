import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import TextEditor from "../../../../partials/TextEditor";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useData } from "../../../../../contexts/DataContext";
import dateDifference from "../../../../utils/DateHelper";
import { postN, putN } from "./services";
import { useUtil } from "../../../../../contexts/UtilContext";
import toast from "react-hot-toast";

// const Index = ({ modalOpen, setModalOpen, updateNote, postNote, selectedNote }) => {
const Index = ({ selectedNote, getNotes, projectId, categoryId }) => {
  const { projectIdentity } = useAuth();
  const { projectTheme, noteModalOpen, setNoteModalOpen } = useData();
  const { showLoader } = useUtil();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [colorState, setColorState] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    // console.log("selectedNote", selectedNote);
    if (selectedNote) {
      setTitle(selectedNote.title);
      setDescription(selectedNote.description);
      setColorState(selectedNote.color);
    } else {
      setTitle("");
      setDescription("");
      setColorState(0);
    }

    setDataChanged(false);
  }, [noteModalOpen]);

  // POST requests
  const postNote = async (formData) => {
    showLoader(true);
    await postN(
      projectId,
      categoryId,
      formData
    )(
      (message) => {
        getNotes();
        toast.success(message);
      },
      (message) => {
        toast.error(message);
        showLoader(false);
      }
    );
  };

  // PUT requests
  const updateNote = async (formData) => {
    showLoader(true);
    await putN(
      projectId,
      categoryId,
      formData
    )(
      (message) => {
        getNotes();
        toast.success(message);
      },
      (message) => {
        toast.error(message);
        showLoader(false);
      }
    );
  };

  const submit = (e) => {
    if (e) e.preventDefault();

    const formData = {
      user_id: projectIdentity.id,
      title: title,
      description: description,
      color: colorState,
    };

    if (selectedNote) {
      formData.id = selectedNote.id;
      updateNote(formData);
    } else {
      postNote(formData);
    }
  };

  const onClose = () => {
    // if modal closed while empty
    if (!title && !description) {
      setNoteModalOpen(false);
      return;
    }
    console.log("dataChanged: " + dataChanged);

    // if modal closed while a note is selected
    if (selectedNote) {
      // If data wasn't changed, don't submit the form
      if (!dataChanged) {
        setNoteModalOpen(false);
        return;
      }
    }

    setNoteModalOpen(false);
    submit();
  };

  return (
    <Modal isOpen={noteModalOpen} onRequestClose={onClose} className="modalC col-11 col-sm-9 col-lg-7 bg-none z-2" shouldFocusAfterRender={false}>
      <div data-aos="fade-up" className="p-3 shadow border-sm rounded" style={{ backgroundColor: projectTheme.theme.notes[colorState] }}>
        <form>
          <input
            className="form-control bg-transparent border-custom-title fs-5"
            placeholder="Title ..."
            type="text"
            name="title"
            value={title}
            onChange={(e) => {
              setDataChanged(true);
              // console.log("Data Changed (title)");
              setTitle(e.target.value);
            }}
            required
          />

          <TextEditor
            value={description}
            onChange={(value) => {
              setDescription(value);
              setDataChanged(true);
              // console.log("Data Changed (TextEditor)");
            }}
          />

          <div className="Picker d-flex mb-2 p-1 border-custom-color overflow-auto scrollbar-hidden">
            {projectTheme?.theme.notes?.map((color, index) => (
              <div className="form-check me-1 p-0" key={index}>
                <input
                  className="btn-check"
                  id={`color${index}`}
                  type="radio"
                  name="color"
                  value={index}
                  checked={colorState == index}
                  onChange={(e) => {
                    setColorState(e.target.value);
                    setDataChanged(true);
                    // console.log("Data Changed (color)");
                  }}
                />
                <label className="btn p-1" htmlFor={`color${index}`}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: color,
                    }}
                  ></div>
                </label>
              </div>
            ))}
          </div>
        </form>
        {selectedNote && (
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <p className="text-muted small mb-0">{selectedNote.author ? `${selectedNote.author.display_name}'s Note` : "-"}</p>

            {selectedNote.editor && selectedNote.editor?.id != selectedNote.author?.id ? (
              <p className="text-muted small mb-0">
                Edited {dateDifference(new Date(selectedNote.updated_at))} By {selectedNote.editor ? selectedNote.editor.display_name : "-"}
              </p>
            ) : (
              <p className="text-muted small mb-0">
                Created {dateDifference(new Date(selectedNote.updated_at))} By {selectedNote.author ? selectedNote.author.display_name : "-"}
              </p>
            )}

            <button className="btn btn-primary shadow-sm" onClick={onClose}>
              Close
            </button>
          </div>
        )}

        {!selectedNote && (
          <div className="d-flex align-items-center justify-content-end">
            <button className="btn btn-primary shadow-sm" onClick={onClose}>
              Close
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Index;
