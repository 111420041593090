import React, { useEffect, useState } from "react";
import { useUtil } from "../../../../contexts/UtilContext";
import LoginForm from "./form";
import { login } from "./services";
import { useAuth } from "../../../../contexts/AuthContext";
import Navbar from "../../../partials/Navbar";
import { getToken } from "../../../../config/authConfig";
import { useNavigate } from "react-router-dom";

function Login() {
  const { showLoader } = useUtil();
  const [errorMessage, setErrorMessage] = useState("");
  const { loginIdentity, logoutIdentity } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (getToken()) {
      navigate("/");
    }
  }, []);

  const authorize = async (formData) => {
    const data = {
      data: formData,
    };

    showLoader(true);
    await login(data)(
      (response) => {
        let currentUser = response.data.user;
        if (currentUser.projects.length == 0) {
          logoutIdentity();
        }
        loginIdentity(currentUser, response.data.token, `/${currentUser.projects[0].id}/${currentUser.projects[0].categories[0].id}`);
      },
      (message) => {
        setErrorMessage(message);
      }
    );
    showLoader(false);
  };

  return (
    <>
      <Navbar />
      <div className="container my-4">
        <div className="mx-auto col-12 col-md-9 d-flex flex-column justify-content-center">
          <h1 className="m-0 text-center">Login</h1>
          <LoginForm authorize={authorize} errorMessage={errorMessage} />
        </div>
      </div>
    </>
  );
}

export default Login;
