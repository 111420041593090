import axios from "axios";

// GET requests
export const getP = (projectId) => async (onSuccess, onFail) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}`);

  if (response.code == 1) {
    onSuccess(response.data);
  } else {
    onFail(response.message);
  }
};

// GET requests
export const getC = (projectId, categoryId) => async (onSuccess, onFail) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}`);

  if (response.code == 1) {
    onSuccess(response.data);
  } else {
    onFail(response.message);
  }
};

// GET requests
export const getN = (projectId, categoryId) => async (onSuccess, onFail) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}/notes`);

  if (response.code == 1) {
    onSuccess(response.data);
  } else {
    onFail(response.message);
  }
};

export const moveToTrash = (projectId, categoryId, note) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}/notes/${note.id}/trash`);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};


