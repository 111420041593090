import React, { useEffect, useState } from "react";
import { getP, getTrashed } from "./services";
import { useUtil } from "../../../contexts/UtilContext";
import { useAuth } from "../../../contexts/AuthContext";
import { useData } from "../../../contexts/DataContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import TrashNotes from "./TrashNotes";
// import Sidebar from "../../partials/Sidebar";
// import Notes from "./Notes";
// import UserSettings from "./Modals/UserSettings";
// import ProjectMembers from "./Modals/ProjectMembers";
// import ProjectCustomization from "./Modals/ProjectCustomization";
import ProjectNavbar from "../../partials/ProjectNavbar";

function Index() {
  const { identity, setProjectIdentity } = useAuth();
  const { showLoader } = useUtil();
  const { projectId } = useParams();
  const { themes, setProjectTitle, setProjectTheme, setProjectCategories, userSettingsModalOpen, projectMembersModalOpen, projectCustomizationModalOpen } = useData();

  const [searchParams] = useSearchParams();
  const isTrash = searchParams.has("trash");

  const [notes, setNotes] = useState(null);
  const [project, setProject] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      console.warn("GET Project");
      getProject();
      getTrashedNotes();
    }
  }, [projectId]);

  // useEffect(() => {
  //   if (categoryId) {
  //     console.warn("GET Category");
  //     if (categoryId) {
  //       getCategory();
  //     }
  //   }
  // }, [categoryId, setProjectCategories]);

  const getProject = async () => {
    showLoader(true);

    await getP(projectId)(
      (projectData) => {
        setProject(projectData);
        setProjectTitle(projectData.title);

        // Set Project Identity if indetity exist
        if (identity) {
          const user = projectData.users.find((user) => user.id == identity.id);
          if (user) {
            setProjectIdentity(user.pivot);
          }
        }

        if (themes) {
          let projectThemeObj = {
            index: projectData.theme,
            theme: themes[projectData.theme],
          };

          setProjectTheme(projectThemeObj);
        }
      },
      (message) => {
        toast.error(message);
        setProject(null);
      }
    );
    showLoader(false);
  };

  // GET requests
  const getTrashedNotes = async () => {
    showLoader(true);

    await getTrashed(projectId)(
      (data) => {
        setNotes(data);
      },
      (message) => {
        toast.error(message);
        setNotes(null);
      }
    );
    showLoader(false);
  };

  return (
    <div className="theme-custom vh-100">
      {identity && project && (
        <>
          <ProjectNavbar project={project} projectId={projectId} category={null} isTrash={true} />

          <div className="position-relative ">
            {/* <Sidebar project={project} projectId={projectId} categoryId={categoryId} isTrash={isTrash} /> */}

            <div className="body-trash-content p-2">
              <div className="custom-overflow scrollbar-hidden row g-2 m-0 pb-2 h-100 align-content-start">
                <TrashNotes projectId={projectId} notes={notes} getTrashedNotes={getTrashedNotes} />
              </div>
            </div>
          </div>

          {/* {userSettingsModalOpen && <UserSettings />}
          {projectMembersModalOpen && <ProjectMembers projectId={projectId} project={project} setProject={setProject} />}
          {projectCustomizationModalOpen && <ProjectCustomization projectId={projectId} setProject={setProject} categoryId={null} setCategory={setCategory} />} */}
        </>
      )}
    </div>
  );
}

export default Index;
