import React, { createContext, useContext, useEffect, useState } from "react";
import { getToken, removeToken, setToken } from "../config/authConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUtil } from "./UtilContext";
import toast from "react-hot-toast";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [identity, setIdentity] = useState(null);
  const [projectIdentity, setProjectIdentity] = useState(null);
  const { showLoader } = useUtil();

  const navigate = useNavigate();

  const getidentityByToken = async () => {
    showLoader(true);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/user`);
    if (response.code == 1) {
      setIdentity(response.data);
    } else {
      toast.error(response.message);
      showLoader(false);
      logoutIdentity();
    }
    showLoader(false);
  };

  useEffect(() => {
    let token = getToken();

    if (!token) {
      console.warn("TOKEN not exist");
      logoutIdentity();
    }

    if (token && !identity) {
      console.warn("TOKEN exist but IDENTITY not");
      getidentityByToken();
    }
  }, []);

  const loginIdentity = (currentUser, token, pathTo) => {
    setToken(token);
    setIdentity(currentUser);
    navigate(pathTo);
  };

  const logoutIdentity = () => {
    removeToken();
    setIdentity(null);
    setProjectIdentity(null);
    navigate("/");
  };

  return <AuthContext.Provider value={{ identity, setIdentity, loginIdentity, logoutIdentity, projectIdentity, setProjectIdentity }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
