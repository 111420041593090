import React, { lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Project from "./components/views/Project";
import Trash from "./components/views/Trash";
import Login from "./components/views/Auth/Login";
import { Toaster } from "react-hot-toast";
import AuthRoutes from "./AuthRoutes";
import Index from "./components/views";
import Signup from "./components/views/Auth/Signup";
import Aos from "aos";
import 'aos/dist/aos.css';

function Router() {

  
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, [])

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Index />} />

        {/* Guest Routes */}
        <Route path="/login" exact element={<Login />} />
        <Route path="/signup" exact element={<Signup />} />

        {/* Auth Routes */}
        <Route element={<AuthRoutes />}>
          <Route path="/:projectId/:categoryId" element={<Project />} />
          <Route path="/:projectId/trash" element={<Trash />}/>
        </Route>
        {/* Other Routes, Redirect to Index */}
        <Route path="*" element={<Index />} />
      </Routes>

      <Toaster position="bottom-center" />
    </>
  );
}

// function NotFound() {
//   return (
//     <div>
//       <h1>404 - Not Found</h1>
//       <p>Sorry, the page you are looking for does not exist.</p>
//     </div>
//   );
// }

export default Router;
