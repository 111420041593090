import React, { useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../../../../../contexts/AuthContext";
import { updateFullName, updatePassword } from "./services";
import { useUtil } from "../../../../../contexts/UtilContext";
import toast from "react-hot-toast";
import { useData } from "../../../../../contexts/DataContext";

function UserSettings() {
  const { identity, setIdentity } = useAuth();
  const { showLoader } = useUtil();
  const { userSettingsModalOpen, setUserSettingsModalOpen } = useData();

  const [name, setName] = useState(identity.name);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [nameError, setNameError] = useState("");
  const [showSub1, setShowSub1] = useState(false);
  const [showSub2, setShowSub2] = useState(false);

  const submitName = async (e) => {
    e.preventDefault();
    if (name == identity.name) {
      setNameError("The provided name is the same as the current name");
      return;
    } else {
      setNameError("");
    }

    const formData = {
      id: identity.id,
      name: name,
    };

    showLoader(true);
    await updateFullName(formData)(
      (message) => {
        let oldIdentity = identity;
        oldIdentity.name = name;
        setIdentity(oldIdentity);
        setShowSub1(false);
        setNameError("");
        toast.success(message);
      },
      (message) => {
        setNameError(message);
      }
    );
    showLoader(false);
    // setModalOpen(false);
  };

  const submitPassword = async (e) => {
    e.preventDefault();

    const formData = {
      id: identity.id,
      oldPassword: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    showLoader(true);
    await updatePassword(formData)(
      (message) => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.success(message);
        setShowSub2(false);
      },
      (message) => {
        toast.error(message);
      }
    );
    showLoader(false);
    // setModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={userSettingsModalOpen}
        onRequestClose={() => {
          setUserSettingsModalOpen(false);
        }}
        className="modalC col-11 col-md-9 col-lg-8 border-sm rounded shadow-sm bg-white overflow-hidden"
        shouldFocusAfterRender={false}
      >
        <div data-aos="fade-up" className="p-4">
          <div className="mb-3 d-flex justify-content-between">
            <h4 className="m-0 text-capitalize">Account Settings</h4>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setUserSettingsModalOpen(false);
              }}
            ></button>
          </div>

          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                  Personal Information
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <form
                    onSubmit={(e) => {
                      submitName(e);
                    }}
                    onChange={() => {
                      setShowSub1(true);
                    }}
                  >
                    <div className="mb-3">
                      <label className="form-label mb-1">Username</label>
                      <input type="text" className="form-control" value={identity.username} disabled />
                    </div>

                    <div className="mb-2">
                      <label htmlFor="l2" className="form-label mb-1">
                        Full Name
                      </label>
                      <input type="text" className={`form-control ${nameError && "border-danger"}`} id="l2" value={name} onChange={(e) => setName(e.target.value)} />
                      <small className="text-muted">This is your default display name</small>
                      {nameError && (
                        <>
                          <br />
                          <small className="text-danger">{nameError}</small>
                        </>
                      )}
                    </div>

                    {showSub1 && (
                      <button type="submit" className="btn btn-primary text-white">
                        Save Changes
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  Change Password
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <form
                    onSubmit={(e) => {
                      submitPassword(e);
                    }}
                    onChange={() => {
                      setShowSub2(true);
                    }}
                  >
                    <div className="mb-3">
                      <label htmlFor="lp1" className="form-label">
                        Old Password
                      </label>
                      <input type="password" className="form-control" id="lp1" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="lp2" className="form-label">
                        New Password
                      </label>
                      <input type="password" className="form-control" id="lp2" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </div>

                    <div className="mb-2">
                      <label htmlFor="lp3" className="form-label">
                        Confirrm Password
                      </label>
                      <input type="password" className="form-control" id="lp3" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>

                    {showSub2 && (
                      <button type="submit" className="btn btn-primary text-white">
                        Save Changes
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default UserSettings;
