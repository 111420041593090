import React from "react";
import Icon from "./Icon";
import ProfilePopup from "./ProfilePopup";
import { useUtil } from "../../contexts/UtilContext";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

function ProjectNavbar({ project, projectId, category, isTrash }) {
  const { identity } = useAuth();
  const { sidebarCollapsed, setSidebarCollapsed } = useUtil();

  const collapseSideBarToggle = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className="fixed-top bg-white w-100 border-bottom d-flex align-items-center justify-content-between shadow-sm z-1">
      <div className="d-flex align-items-center justify-content-center px-3 py-2" style={{ width: "60px" }}>
        {isTrash ? (

          <Link className="link w-100 btn d-flex justify-content-center" to={`/${identity.projects[0].id}/${identity.projects[0].categories[0].id}`}>
            <Icon icon={"fa fa-angle-left text-muted"} size={"1.2rem"} />
          </Link>
        ) : (
          <button id="sidebar-menu" className="w-100 btn d-flex justify-content-center" onClick={collapseSideBarToggle}>
            <Icon icon={"fa-solid fa-bars text-muted"} size={"1.2rem"} />
          </button>
        )}
      </div>
      <h1 className="me-auto mb-0 d-flex h4">
        <div className="d-flex align-items-center">
          {isTrash ? (
            <>
              <span className="me-2">Trash</span>
              {/* <Icon size="1.5rem" icon={"fa-regular fa-trash-can"} /> */}
            </>
          ) : (
            <>
              <span className="me-2"> {category?.title}</span>
              {/* <Icon size="1.5rem" iconIndex={category.icon} /> */}
            </>
          )}
        </div>
      </h1>
      {/* <div style={{ padding: "10px" }}> */}
      {/* <button className="btn border-none bg-transparent  ">
          <Icon icon={"fa-solid fa-expand"} size={"1.2rem"} />
        </button> */}
      {/* </div> */}

      <div style={{ padding: "10px" }}>
        <ProfilePopup project={project} projectId={projectId} />
      </div>
    </div>
  );
}

export default ProjectNavbar;
