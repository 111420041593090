import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";

function Navbar() {
  const { identity } = useAuth();

  return (
    <div className="sticky-top bg-white">
      <div className="container ">
        <nav id="navbar" className="navbar navbar-expand-md ">
          <div className="container-fluid">
            <Link className="link navbar-brand" to={`/`}>
              Dear Diary
            </Link>
            <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mx-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <a aria-current="page" className="nav-link active" href="/#home">
                    Home
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="/#about">
                    About
                  </a>
                </li> */}

                <li className="nav-item">
                  <a className="nav-link" href="/#features">
                    Features
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/#gallery">
                    Gallery
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/#pricing">
                    Pricing
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/#contact">
                    Contact
                  </a>
                </li>
              </ul>

              {identity ? (
                <Link className="btn btn-primary" to={`/${identity.projects[0].id}/${identity.projects[0].categories[0].id}`}>
                  My Diary
                </Link>
              ) : (
                <>
                  <Link className="btn btn-primary me-1" to={"/login"}>
                    Login
                  </Link>
                  <Link className="btn btn-outline-primary" to={"/signup"}>
                    Create My Diary
                  </Link>
                </>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
