import React from "react";
import NoteCard from "../../../partials/NoteCard";
import { deleteN, restore } from "../services";
import toast from "react-hot-toast";
import { useUtil } from "../../../../contexts/UtilContext";

function TrashNotes({ projectId, notes, getTrashedNotes }) {
  const { showLoader } = useUtil();

  // PUT requests (remove from Trash)
  const restoreNote = async (e, note) => {
    e.stopPropagation();
    e.preventDefault();

    showLoader(true);
    await restore(
      projectId,
      // categoryId,
      note
    )(
      (message) => {
        toast.success(message);
        getTrashedNotes();
      },
      (message) => {
        toast.error(message);
        showLoader(false);
      }
    );
    // showLoader(false);
  };

  // DELETE requests
  const deleteNote = async (e, note) => {
    e.stopPropagation();
    e.preventDefault();

    if (!window.confirm('Are you sure you want to permanently delete "' + note.title + '" note?')) return;

    showLoader(true);
    await deleteN(
      projectId,
      // categoryId,
      note
    )(
      (message) => {
        toast.success(message);
        getTrashedNotes();
      },
      (message) => {
        toast.error(message);
        showLoader(false);
      }
    );
    // showLoader(false);
  };

  return (
    <>
      {notes && notes.length > 0 ? (
        notes.map((note) => (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2" key={note.id}>
            <NoteCard note={note} deleteNote={deleteNote} restoreNote={restoreNote} />
          </div>
        ))
      ) : (
        <small className="text-muted p-3">Trash is empty</small>
      )}
    </>
  );
}

export default TrashNotes;
