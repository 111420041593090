import React, { createContext, useContext, useState } from "react";
import Loader from "../components/partials/Loader";

const UtilContext = createContext();

export const UtilProvider = ({ children }) => {
    const [isLoading, showLoader] = useState(false);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);

    return (
        <UtilContext.Provider value={{ showLoader, sidebarCollapsed, setSidebarCollapsed }}>
            {isLoading ? <Loader loading={isLoading} /> : ""}
            {children}
        </UtilContext.Provider>
    );
};

export const useUtil = () => useContext(UtilContext);
