import axios from "axios";

// GET requests
export const getP = (projectId) => async (onSuccess, onFail) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}`);

  if (response.code == 1) {
    onSuccess(response.data);
  } else {
    onFail(response.message);
  }
};


// GET requests
export const getTrashed = (projectId) => async (onSuccess, onFail) => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/notes-trashed`);

  if (response.code == 1) {
    onSuccess(response.data);
  } else {
    onFail(response.message);
  }
};


export const restore = (projectId, note) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/notes/${note.id}/restore`);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

export const deleteN = (projectId, note) => async (onSuccess, onFail) => {
  const response = await axios.delete(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/notes/${note.id}`);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

// export const emptyTrash = () => async (onSuccess, onFail) => {
//   const response = await axios.delete(`${process.env.REACT_APP_SERVER_URI}/api/notes/trash/empty`);

//   if (response.code == 1) {
//     onSuccess(response.message);
//   } else {
//     onFail(response.message);
//   }
// };
