import React  from 'react';
import { createRoot } from "react-dom/client";
import App from "./App";
import "./config/axiosConfig";
import Modal from "react-modal";
import 'bootstrap';
import './sass/app.scss';

// Set the app element for React Modal
Modal.setAppElement("#modal");

const root = createRoot(document.getElementById('root'));

// Render the app
root.render(
    // <React.StrictMode >
        <App />
    // </React.StrictMode>
);

