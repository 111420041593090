import React, { createContext, useContext, useEffect, useState } from "react";
import themesData from "../data/themes.json";
import iconsData from "../data/icons.json";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [themes, setThemes] = useState(themesData);
  const [icons, setIcons] = useState(iconsData);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectTheme, setProjectTheme] = useState(null);
  const [projectCategories, setProjectCategories] = useState([]);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [userSettingsModalOpen, setUserSettingsModalOpen] = useState(false);
  const [projectMembersModalOpen, setProjectMembersModalOpen] = useState(false);
  const [projectCustomizationModalOpen, setProjectCustomizationModalOpen] = useState(false);

  useEffect(() => {
    if (projectTheme != null && themes != null) {
      document.documentElement.style.setProperty("--theme-primary", projectTheme.theme.primary, "important");
      // document.documentElement.style.setProperty("--theme-secondary", projectTheme.theme.secondary, "important");
    }
  }, [projectTheme]);

  return (
    <DataContext.Provider
      value={{
        themes,
        projectTitle,
        setProjectTitle,
        projectTheme,
        setProjectTheme,
        projectCategories,
        setProjectCategories,
        icons,
        noteModalOpen,
        setNoteModalOpen,
        userSettingsModalOpen,
        setUserSettingsModalOpen,
        projectMembersModalOpen,
        setProjectMembersModalOpen,
        projectCustomizationModalOpen,
        setProjectCustomizationModalOpen,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
