import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import { useUtil } from "../../contexts/UtilContext";
import { useAuth } from "../../contexts/AuthContext";
import { useData } from "../../contexts/DataContext";

function Sidebar({ project, projectId, categoryId }) {
  const { identity } = useAuth();
  const { projectCategories, projectTitle } = useData();
  const { sidebarCollapsed, setSidebarCollapsed } = useUtil();

  const [touchStartX, setTouchStartX] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    if (touchEndX > touchStartX) {
      // console.log("Dragging to the right");
      setSidebarCollapsed(false)
    } else {
      setSidebarCollapsed(true)
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(0);
  };

  // const collapseSideBarToggle = () => {
  //   setSidebarCollapsed(!sidebarCollapsed);
  // };

  return (
    <>
      {identity && project && (
        <header className="mt-4 position-fixed top-50 start-0 translate-middle-y d-flex align-items-center z-1" style={{height: "85%"}} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <div id="sidebar" className={`d-flex flex-column flex-shrink-0 sidebar-wrap h-100 ${sidebarCollapsed ? "sidebar-collapsed" : "sidebar-expanded"}`}>
            <ul className="nav nav-pills flex-column  scrollbar-hidden">
              {/* <li>
                <button id="sidebar-menu" className="w-100 nav-link" onClick={collapseSideBarToggle}>
                  <div className="icon-wrap">
                    <Icon icon={`fa ${sidebarCollapsed ? "fa-chevron-right" : "fa-chevron-left"}`} />
                  </div>
                  <span className="text-nowrap">{projectTitle}</span>
                </button>
              </li> */}

              {projectCategories.map((category) => (
                <Link key={category.id} className="link" to={`/${project.id}/${category.id}`}>
                  <li>
                    <button className={`w-100 nav-link ${categoryId == category.id ? "active" : ""}`}>
                      <div className="icon-wrap">
                        <Icon icon={"sidebar-icon"} iconIndex={category.icon} />
                      </div>
                      <span className="text-nowrap">{category.shortTitle}</span>
                    </button>
                  </li>
                </Link>
              ))}

              <li>
                <Link className="link" to={`/${projectId}/trash`}>
                  <button className={`w-100 nav-link`}>
                    <div className="icon-wrap">
                      {/* <TrashIcon width={"25px"} /> */}
                      <Icon icon={"sidebar-icon fa-regular fa-trash-can"} />
                    </div>
                    <span className="text-nowrap">Trash</span>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </header>
      )}
    </>
  );
}

export default Sidebar;
