import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../../../../../contexts/AuthContext";
import { addUser, deleteUser } from "./services";
import { useUtil } from "../../../../../contexts/UtilContext";
import toast from "react-hot-toast";
import Icon from "../../../../partials/Icon";
import { useData } from "../../../../../contexts/DataContext";

function Index({ projectId, project }) {
  const { identity } = useAuth();
  const { showLoader } = useUtil();
  const { projectTitle, projectMembersModalOpen, setProjectMembersModalOpen } = useData();
  const [projectUsers, setProjectUsers] = useState([]);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    console.warn("Project Users Updated");
    setProjectUsers(project.users);
  }, [project]);

  const deleteMember = async (userId) => {
    // if (!window.confirm("Are you sure you want to remove this member?")) return;

    const formData = {
      userId: userId,
    };

    showLoader(true);
    await deleteUser(projectId, formData)(
      (message) => {
        let oldUsers = projectUsers;
        oldUsers = oldUsers.filter((user) => user.id !== userId);
        setProjectUsers(oldUsers);

        toast.success(message);
      },
      (message) => {
        toast.error(message);
      }
    );
    showLoader(false);
  };

  const addMember = async (e) => {
    e.preventDefault();

    const formData = {
      email: email,
    };

    showLoader(true);
    await addUser(projectId, formData)(
      (response) => {
        let oldUsers = projectUsers;
        let addedUser = response.data;
        // // Define the pivot object with display_name
        // let pivot = {
        //   display_name: addedUser.name, // Set display_name to addedUser's name
        // };

        // // Add pivot object to addedUser
        // addedUser.pivot = pivot;

        // console.log(addedUser);
        oldUsers.push(addedUser);
        setProjectUsers(oldUsers);

        setEmail("");
        setEmailError("");
        toast.success(response.message);
      },
      (message) => {
        // toast.error(message);
        setEmailError(message);
      }
    );
    showLoader(false);
  };

  return (
    <>
      <Modal
        key={"membersModal"}
        isOpen={projectMembersModalOpen}
        onRequestClose={() => {
          setProjectMembersModalOpen(false);
        }}
        className="modalC col-11 col-md-9 col-lg-8 border-sm rounded shadow-sm bg-white overflow-hidden"
        shouldFocusAfterRender={false}
      >
        <div data-aos="fade-up" className="p-4">
          <div className="mb-3 d-flex justify-content-between">
            <h4 className="m-0 text-capitalize">{projectTitle} Members</h4>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setProjectMembersModalOpen(false);
              }}
            ></button>
          </div>

          <form
            onSubmit={(e) => {
              addMember(e);
            }}
          >
            <label htmlFor="l1" className="form-label">
              User Email
            </label>
            <div className="mb-2 d-flex justify-content-end">
              <div className="col">
                <input type="text" className={`form-control ${emailError && "border-danger"}`} id="l1" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Member Email" required />
                {emailError && (
                  <>
                    <small className="text-danger">{emailError}</small>
                  </>
                )}
              </div>
              <button type="submit" className="btn btn-primary text-white ms-1">
                Add
              </button>
            </div>
          </form>

          <div className="custom-overflow modal-height">
            <div className="table-responsive">
              <table className="table table-striped mb-2">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Display Name</th>
                    <th scope="col">User Email</th>
                  </tr>
                </thead>
                <tbody>
                  {projectUsers.map((user, index) => {
                    return (
                      <tr key={user.id}>
                        <td scope="row">{++index}</td>
                        <td>{user.pivot?.display_name ?? user.name}</td>
                        <td>{user.email}</td>
                        <td>
                          {user.id != identity.id && (
                            <div
                              onClick={() => {
                                deleteMember(user.id);
                              }}
                              style={{ width: "fit-content", cursor: "pointer" }}
                            >
                              <Icon icon={"fa-solid fa-user-minus link-danger"} size={"13px"} />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Index;
