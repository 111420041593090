import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.bubble.css";
// import textEditorToolbar from "../../data/textEditorToolbar.json";
import EditorToolbar, { modules, formats } from "./EditorToolbar";

// import ImageResize from 'quill-image-resize-module-react';

function TextEditor({ value, onChange }) {
  // Quill.register('modules/imageResize', ImageResize);

  // const modules = {
  //   toolbar: textEditorToolbar,
  //   // imageResize: {
  //   //   modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
  //   // },
  // };

  // const BubbleTheme = Quill.import("themes/bubble");

  // class ExtendBubbleTheme extends BubbleTheme {
  //   constructor(quill, options) {
  //     super(quill, options);

  //     quill.on("selection", (range) => {
  //       if (range) {
  //         quill.theme.tooltip.show();
  //         quill.theme.tooltip.position(quill.getBounds(range));
  //       }
  //     });
  //   }
  // }

  // Quill.register("themes/bubble", ExtendBubbleTheme);

  return (
    <>
      <div className="text-editor">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          // theme="bubble"
          value={value}
          onChange={onChange}
          placeholder={"Write something awesome..."}
          modules={modules}
          formats={formats}
        />
      </div>

      {/* <ReactQuill value={value} onChange={onChange} modules={modules} placeholder="Write something awesome..."  /> */}
    </>
  );
}

export default TextEditor;
