import React from "react";
import Icon from "./Icon";
import { useData } from "../../contexts/DataContext";
import dateDifference from "../utils/DateHelper";

function NoteCard({ note, deleteNote, restoreNote }) {
  const { projectTheme } = useData();

  return (
    <>
      <div
        data-aos="zoom-in"
        className="p-3 pb-1 rounded shadow-sm "
        style={{
          backgroundColor: projectTheme.theme.notes[note.color],
        }}
      >
        <div className="overflow-hidden scrollbar-hidden note-description mb-1" style={{ height:"150px"  }}>
          <h5>{note.title}</h5>
          <div className="note-card-description" dangerouslySetInnerHTML={{ __html: note.description }}></div>
        </div>

        <div className="d-flex flex-wrap align-items-center justify-content-between py-2">
          <div>
            <span>
              <button
                className="btn p-0 border-0 "
                onClick={(e) => {
                  deleteNote(e, note);
                }}
              >
                <Icon icon="fa-regular fa-trash-can me-2 text-muted" size="0.9rem" />
              </button>
            </span>

            {restoreNote && (
              <span className="ms-2">
                <button
                  className="btn p-0 border-0"
                  onClick={(e) => {
                    restoreNote(e, note);
                  }}
                >
                  <Icon icon="fa-solid fa-rotate-left me-2 text-muted" size="0.9rem" />
                </button>
              </span>
            )}
          </div>

          <small className="text-muted text-center ">{dateDifference(new Date(note.updated_at))}</small>

          {note.editor && note.editor?.id != note.author?.id ? <small className="text-muted">By {note.editor ? note.editor.display_name : "-"}</small> : <small className="text-muted">By {note.author ? note.author.display_name : "-"}</small>}

          {/* <small className="text-muted">By {note.editor ? note.editor.display_name : "-"}</small> */}
        </div>
      </div>
    </>
  );
}

export default NoteCard;
