import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Navbar from "../partials/Navbar";
import Icon from "../partials/Icon";

function Index() {
  const { identity } = useAuth();
  return (
    <>
      <Navbar />
      <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true" tabIndex="0">
        {/* Navigation */}
        {/* Hero Section */}
        <section data-aos="zoom-in" id="home" className="hero bg-light py-2">
          <div className="container py-4">
            <div className="row g-2 align-items-center justify-content-between">
              <div className="col-12 col-md-7 p-2 text-center text-md-start">
                <p className="text-muted mb-0">Memories Worth Keeping</p>
                <h1>Welcome to Dear Diary</h1>
                <p>Discover a customizable platform to document your life's journey, share precious memories with loved ones, and stay effortlessly organized. Enjoy personalized experiences, create shared spaces, integrate multimedia, and explore a variety of customizable themes to make your diary uniquely yours.</p>
                <div>
                  {/* <button className="btn btn-primary me-1">
                    <Icon icon={"fa-brands fa-google-play"} /> Google play
                  </button>

                  <button className="btn btn-primary">
                    <Icon icon={"fa-brands fa-apple"} /> App Store
                  </button> */}

                  {identity ? (
                    <Link className="btn btn-primary" to={`/${identity.projects[0].id}/${identity.projects[0].categories[0].id}`}>
                      My Diary
                    </Link>
                  ) : (
                    <>
                      <Link className="btn btn-primary me-1" to={"/login"}>
                        Login
                      </Link>
                      <Link className="btn btn-outline-primary" to={"/signup"}>
                        Create My Diary
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-5 p-2">
                <img className="img-fluid" src={require("../../img/hero.png")} alt="Hero" />
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section data-aos="zoom-in" id="features">
          <div className="container text-center py-4">
            <h2>Features</h2>
            <p>At Dear Diary, we offer a variety of features to enhance your journaling experience</p>
            <div className="row">
              <div className="col-md-4 feature-item" style={{ marginBottom: "30px" }}>
                <div className="thumbnail">
                  <div className="caption">
                    <h4>Personalized Experiences</h4>
                    <p>Customize your diary to reflect your unique style and preferences.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 feature-item" style={{ marginBottom: "30px" }}>
                <div className="thumbnail">
                  <div className="caption">
                    <h4>Shared Spaces</h4>
                    <p>Share your special moments with loved ones and create joint memories.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 feature-item" style={{ marginBottom: "30px" }}>
                <div className="thumbnail">
                  <div className="caption">
                    <h4>Multimedia Integration</h4>
                    <p>Incorporate photos, videos, and audio to bring your diary entries to life.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section data-aos="zoom-in" id="gallery" style={{ height: "500px" }}>
          <div className="container text-center py-4">
            <h2>Gallery</h2>
            <p>Explore some of the beautiful diaries created by our users. The possibilities are endless with Dear Diary</p>
          </div>
        </section>
        <section data-aos="zoom-in" id="pricing" style={{ height: "500px" }}>
          <div className="container text-center py-4">
            <h2>Pricing</h2>
            <p>Choose the plan that best suits your needs</p>
          </div>
        </section>

        <section data-aos="zoom-in" id="contact" style={{ height: "500px" }}>
          <div className="container text-center py-4">
            <h2>Contact</h2>
            <p>We'd love to hear from you! If you have any questions, feedback, or need assistance, please reach out to us</p>
          </div>
        </section> */}

        {/* Footer */}
        <footer className="container">
          <div className="text-center py-3 bg-light">
            <p className="m-0">Powered by Sparklyminds &copy; 2024</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Index;
