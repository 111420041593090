import axios from "axios";
import { getToken } from "./authConfig";

// Default defaults
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.withCredentials = true;

// Request interceptor
axios.interceptors.request.use(
    function (config) {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        console.log("(Axios Config) - Request error:", error);
        // return Promise.reject(error);
        return { code: 0, message: "Network Error", data: null };
    }
);

// Response interceptor
axios.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        // Handle unauthorized error (401 status code)
        if (error.response && error.response.status === 401) {
            console.log("(Axios Config) - Unauthorized error:", error);
            window.location.href = "/";
            return { code: 0, message: "Unauthorized", data: null };
        }

        // Handle forbidden error (403 status code)
        if (error.response && error.response.status === 403) {
            console.log("(Axios Config) - Forbidden error:", error);
            window.location.href = "/";
            return { code: 0, message: "Forbidden", data: null };
        }

        // Log other types of errors for debugging
        console.log("(Axios Config) - Response error:", error);
            // window.location.href = "/";
            return { code: 0, message: "Unexpected error. Please try again later", data: null };
    }
);
