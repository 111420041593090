import axios from "axios";

export const postN = (projectId, categoryId, formData) => async (onSuccess, onFail) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}/notes`, formData);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};

export const putN = (projectId, categoryId, formData) => async (onSuccess, onFail) => {
  const response = await axios.put(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/categories/${categoryId}/notes/${formData.id}`, formData);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};
