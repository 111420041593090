const dateDifference = (date) => {
    const now = new Date(); // Get the current date

    // Calculate the difference in milliseconds
    const Difference_In_Time = now - date;

    // Convert the difference to days
    const Difference_In_Days = Math.floor(
        Difference_In_Time / (1000 * 60 * 60 * 24)
    );

    // Calculate the difference in months and years
    const Difference_In_Months =
        now.getMonth() -
        date.getMonth() +
        12 * (now.getFullYear() - date.getFullYear());
    const Difference_In_Years = now.getFullYear() - date.getFullYear();

    if (Difference_In_Days === 0) {
        return "Today";
    } else if (Difference_In_Days === 1) {
        return "Yesterday";
    } else if (Difference_In_Days < 30) {
        return `${Math.abs(Difference_In_Days)} days ago`;
    } else if (Difference_In_Days < 365) {
        return `${Math.abs(Difference_In_Months)} months ago`;
    } else {
        return `${Math.abs(Difference_In_Years)} years ago`;
    }
};

export default dateDifference;
