import React from "react";
import { InfinitySpin } from "react-loader-spinner";

function Loader({ loading }) {
    return (
        <div className="loading-container">
            <InfinitySpin
                visible={loading}
                ariaLabel="infinity-spin-loading"
            />
        </div>
    );
}

export default Loader;
