import axios from "axios";

export const signup = (data) => async (onSuccess, onFail) => {
    console.log("Signup hit!");
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/signup`, data);
  
    if (response.code == 1) {
      onSuccess(response);
    } else {
      onFail(response.message);
    }
  };
  