import React from "react";
import NoteCard from "../../../partials/NoteCard";
import { moveToTrash } from "../services";
import toast from "react-hot-toast";
import { useUtil } from "../../../../contexts/UtilContext";
import { useData } from "../../../../contexts/DataContext";

function Notes({ projectId, categoryId, notes, getNotes, setSelectedNote }) {
  const { showLoader } = useUtil();
  const { setNoteModalOpen } = useData();

  // PUT requests (moveToTrash)
  const trashNote = async (e, note) => {
    e.stopPropagation();
    e.preventDefault();

    showLoader(true);
    await moveToTrash(
      projectId,
      categoryId,
      note
    )(
      (message) => {
        getNotes();
        toast.success(message);
      },
      (message) => {
        toast.error(message);
        showLoader(false);
      }
    );
  };

  return (
    <>
        {notes && notes.length > 0 ? (
          notes.map((note) => (
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 "
              key={note.id}
              onClick={() => {
                setSelectedNote(note);
                setNoteModalOpen(true);
              }}
            >
              <NoteCard note={note} deleteNote={trashNote} />
            </div>
          ))
        ) : (
          <small className="text-muted p-3">No notes yet? Time to ink your thoughts here!</small>
        )}

      
    </>
  );
}

export default Notes;
