import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "./config/authConfig";
import { useAuth } from "./contexts/AuthContext";

const AuthRoutes = () => {
  const { identity } = useAuth();

  // Waits for AuthContext to fill up the identity, then it redirect inside
  if (getToken() && identity) {
    return <Outlet />;
  }
  // else {
  //   return <Navigate to="/" />;
  // }
};

export default AuthRoutes;
