import axios from "axios";

export const login = (data) => async (onSuccess, onFail) => {
  console.log("Login hit!");
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/login`, data);

  if (response.code == 1) {
    onSuccess(response);
  } else {
    onFail(response.message);
  }
};

export const logout = () => async (onSuccess, onFail) => {
  console.log("Logout hit!");
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/logout`);

  if (response.code == 1) {
    onSuccess();
  } else {
    onFail(response.message);
  }
};
