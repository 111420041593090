import axios from "axios";

export const addUser = (projectId, data) => async (onSuccess, onFail) => {
  const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/users`, data);

  if (response.code == 1) {
    onSuccess(response);
  } else {
    onFail(response.message);
  }
};

export const deleteUser = (projectId, data) => async (onSuccess, onFail) => {
  const response = await axios.delete(`${process.env.REACT_APP_SERVER_URI}/api/projects/${projectId}/users/${data.userId}`);

  if (response.code == 1) {
    onSuccess(response.message);
  } else {
    onFail(response.message);
  }
};
